body {
   margin: 0; 
}

#a {
   width: 48px
}

#t1 {
   color: "fff"
}